const Index = () => {
  return (
    <div className="home">
      <header className="container mt-3 mb-3 text-center">
        <img src="./images/Raj Enclave Logo.png" alt="" />
      </header>
      <main>
        <section className="hero">
          <img src="./images/bg.jpg" alt="hero bg img" className="hero-bg" />
          <div className="hero_inner container">
            <div className="hero-desc">
              <h1 className="fw-bolder">your new home awaits you.</h1>
              <ul className="fw-bolder">
                <li>2 & 3BHK Flats</li>
                <li>Karaswada, Mapusa - North Goa</li>
                <li>Affordable Pricing!</li>
              </ul>
            </div>
            <div className="hero-form">
              <iframe
                title="form"
                id="form-frame"
                src="https://share.hsforms.com/1Ewax3jmxR-ixlHId2D_Plgdiv0x"
                scrolling="no"
                frameBorder="0"></iframe>
            </div>
          </div>
        </section>

        <section className="location-info">
          <div className="container location-info_inner">
            <div className="mb-4">
              <h3>Raj Enclave</h3>
              <p>
                Raj Enclave is a family centric project with something for
                everyone, with its reach being one of the biggest USPs of the
                project. It is Conveniently connected to the upcoming Mopa
                Airport, Thivim Railway Station, Bus Stand, Panjim City and
                Beaches in its close vicinity of North Goa. This makes it an
                ideal investment opportunity in Goa with very appreciative and
                high returns. The Project also offers the best facilities,
                modern amenities, 5 years of maintenance and safety features.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="nearby">Nearby</h3>
              <ul>
                <li>
                  16 km from Mopa Airport, prominent Beaches like Baga, Anjuna,
                  Vagator, etc.
                </li>
                <li>
                  Within 9 km drive from Thivim Railway Station, Thivim
                  Industrial Estate, Mapusa Market, Vision Hospital Mapusa,
                  Vrundavan Hospital Mapusa, Peddem Sports Complex, Redkar
                  Hospital and Research center.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="rera-number">RERA Number</h3>
              <ul>
                <li>Phase 1: PRGO11190946</li>
                <li>Phase 2: PRGO01200950</li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <p className="text-center mt-3">&copy; Raj Housing Dev. Pvt. Ltd.</p>
      </footer>
    </div>
  );
};

export default Index;

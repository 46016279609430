import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Styles/custom.scss";
import { Fragment } from "react";
import Index from "./Pages/home";

function App() {
  return (
    <Fragment>
      <Index />
    </Fragment>
  );
}

export default App;
